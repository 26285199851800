<template>
  <div>
    <select
      v-if="edit_mode && currenciesAvailable.length > 0"
      class="form-control"
      :value="value"
      @change="$emit('input', $event.target.value)"
    >
      <option disabled="disabled" :value="0">Choose Your Currency</option>
      <option
        v-for="currency in currenciesAvailable"
        :key="currency.id"
        :value="currency.id"
        v-text="`(${currency.code}) ${currency.name}`"
      ></option>
    </select>
    <div
      v-else
      class="flex flex-col justify-around"
      v-text="`(${selectedCurrency.code}) ${selectedCurrency.name}`"
    ></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['value', 'edit_mode'],
  data() {
    return {
      currenciesAvailable: [],
    };
  },
  computed: {
    selectedCurrency() {
      const curr = this.currenciesAvailable.find((currency) => currency.id === this.value);

      if (curr) {
        return curr;
      }

      return {
        code: 'None',
        name: '',
      };
    },
  },
  async mounted() {
    this.currenciesAvailable = await this.getCurrencies();
  },
  methods: {
    ...mapActions({
      getCurrencies: 'touch/commonGetCurrencies',
    }),
  },
};
</script>

<style scoped></style>
